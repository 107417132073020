/* This is a mk-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

import { types, prop, tProp, Model, Ref, idProp } from "mobx-keystone"
import { QueryBuilder } from "mk-gql"




/**
 * AlertCountBase
 * auto generated base class for the model AlertCountModel.
 */
export class AlertCountModelBase extends Model({
    __typename: tProp("AlertCount"),
        complete:prop<number>().withSetter(),
    incomplete:prop<number>().withSetter(),
  }) {
    
  }

export class AlertCountModelSelector extends QueryBuilder {
  get complete() { return this.__attr(`complete`) }
  get incomplete() { return this.__attr(`incomplete`) }
}
export function selectFromAlertCount() {
  return new AlertCountModelSelector()
}

export const alertCountModelPrimitives = selectFromAlertCount().complete.incomplete

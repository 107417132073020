/* This is a mk-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

import type { IObservableArray } from "mobx"
import { types, prop, tProp, Model, Ref, idProp } from "mobx-keystone"
import { QueryBuilder } from "mk-gql"
import type { DeviceHistoryModel } from "./DeviceHistoryModel"
import type { EventModel } from "./EventModel"
import type { SiteModel } from "./SiteModel"
import type { StatsModel } from "./StatsModel"


import { DeviceHistoryModelSelector, deviceHistoryModelPrimitives } from "./DeviceHistoryModel"
import { EventModelSelector, eventModelPrimitives } from "./EventModel"
import { SiteModelSelector, siteModelPrimitives } from "./SiteModel"
import { StatsModelSelector, statsModelPrimitives } from "./StatsModel"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  events: IObservableArray<EventModel>;
  history: IObservableArray<DeviceHistoryModel>;
  site: SiteModel;
  stats: IObservableArray<StatsModel>;
}

/**
 * DeviceBase
 * auto generated base class for the model DeviceModel.
 */
export class DeviceModelBase extends Model({
    __typename: tProp("Device"),
        backupBatteryLevel:prop<number | null>().withSetter(),
    batteryLevel:prop<number | null>().withSetter(),
    cellMeid:prop<string | null>().withSetter(),
    deviceType:prop<string | null>().withSetter(),
    events:prop<(Ref<EventModel> | null)[] | null>(() => []).withSetter(),
    firmwareVersion:prop<string | null>().withSetter(),
    history:prop<(Ref<DeviceHistoryModel> | null)[] | null>(() => []).withSetter(),
    id:prop<number>().withSetter(),
    lastLatitude:prop<number | null>().withSetter(),
    lastLongitude:prop<number | null>().withSetter(),
    lastModified:prop<any | null>().withSetter(),
    manufacturer:prop<string | null>().withSetter(),
    nextAlert:prop<any | null>().withSetter(),
    nextAlertHours:prop<number | null>().withSetter(),
    serialNumber:prop<string>().withSetter(),
    site:prop<Ref<SiteModel> | null>().withSetter(),
    stats:prop<(Ref<StatsModel> | null)[] | null>(() => []).withSetter(),
    status:prop<string | null>().withSetter(),
    telitThingId:prop<string | null>().withSetter(),
  }) {
    getRefId() {
      return String(this.id)
    }
  }

export class DeviceModelSelector extends QueryBuilder {
  get backupBatteryLevel() { return this.__attr(`backupBatteryLevel`) }
  get batteryLevel() { return this.__attr(`batteryLevel`) }
  get cellMeid() { return this.__attr(`cellMeid`) }
  get deviceType() { return this.__attr(`deviceType`) }
  get firmwareVersion() { return this.__attr(`firmwareVersion`) }
  get id() { return this.__attr(`id`) }
  get lastLatitude() { return this.__attr(`lastLatitude`) }
  get lastLongitude() { return this.__attr(`lastLongitude`) }
  get lastModified() { return this.__attr(`lastModified`) }
  get manufacturer() { return this.__attr(`manufacturer`) }
  get nextAlert() { return this.__attr(`nextAlert`) }
  get nextAlertHours() { return this.__attr(`nextAlertHours`) }
  get serialNumber() { return this.__attr(`serialNumber`) }
  get status() { return this.__attr(`status`) }
  get telitThingId() { return this.__attr(`telitThingId`) }
  events(builder?: string | EventModelSelector | ((selector: EventModelSelector) => EventModelSelector)) { return this.__child(`events`, EventModelSelector, builder) }
  history(builder?: string | DeviceHistoryModelSelector | ((selector: DeviceHistoryModelSelector) => DeviceHistoryModelSelector)) { return this.__child(`history`, DeviceHistoryModelSelector, builder) }
  site(builder?: string | SiteModelSelector | ((selector: SiteModelSelector) => SiteModelSelector)) { return this.__child(`site`, SiteModelSelector, builder) }
  stats(builder?: string | StatsModelSelector | ((selector: StatsModelSelector) => StatsModelSelector)) { return this.__child(`stats`, StatsModelSelector, builder) }
}
export function selectFromDevice() {
  return new DeviceModelSelector()
}

export const deviceModelPrimitives = selectFromDevice().backupBatteryLevel.batteryLevel.cellMeid.deviceType.firmwareVersion.lastLatitude.lastLongitude.lastModified.manufacturer.nextAlert.nextAlertHours.serialNumber.status.telitThingId

/* This is a mk-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

import { types, prop, tProp, Model, Ref, idProp } from "mobx-keystone"
import { QueryBuilder } from "mk-gql"
import type { AlertCountModel } from "./AlertCountModel"


import { AlertCountModelSelector, alertCountModelPrimitives } from "./AlertCountModel"


/**
 * AlertCountsBase
 * auto generated base class for the model AlertCountsModel.
 */
export class AlertCountsModelBase extends Model({
    __typename: tProp("AlertCounts"),
        Alarm:prop<AlertCountModel>().withSetter(),
    Info:prop<AlertCountModel>().withSetter(),
    Warning:prop<AlertCountModel>().withSetter(),
  }) {
    
  }

export class AlertCountsModelSelector extends QueryBuilder {
  Alarm(builder?: string | AlertCountModelSelector | ((selector: AlertCountModelSelector) => AlertCountModelSelector)) { return this.__child(`Alarm`, AlertCountModelSelector, builder) }
  Info(builder?: string | AlertCountModelSelector | ((selector: AlertCountModelSelector) => AlertCountModelSelector)) { return this.__child(`Info`, AlertCountModelSelector, builder) }
  Warning(builder?: string | AlertCountModelSelector | ((selector: AlertCountModelSelector) => AlertCountModelSelector)) { return this.__child(`Warning`, AlertCountModelSelector, builder) }
}
export function selectFromAlertCounts() {
  return new AlertCountsModelSelector()
}

export const alertCountsModelPrimitives = selectFromAlertCounts()

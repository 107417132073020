/* This is a mk-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

import { types, prop, tProp, Model, Ref, idProp } from "mobx-keystone"
import { QueryBuilder } from "mk-gql"
import type { UserModel } from "./UserModel"
import type { VaultModel } from "./VaultModel"


import { UserModelSelector, userModelPrimitives } from "./UserModel"
import { VaultModelSelector, vaultModelPrimitives } from "./VaultModel"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  user: UserModel;
  vault: VaultModel;
}

/**
 * LoginHistoryBase
 * auto generated base class for the model LoginHistoryModel.
 */
export class LoginHistoryModelBase extends Model({
    __typename: tProp("LoginHistory"),
        id:prop<number>().withSetter(),
    timestamp:prop<any | null>().withSetter(),
    user:prop<Ref<UserModel> | null>().withSetter(),
    vault:prop<Ref<VaultModel>>().withSetter(),
  }) {
    getRefId() {
      return String(this.id)
    }
  }

export class LoginHistoryModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get timestamp() { return this.__attr(`timestamp`) }
  user(builder?: string | UserModelSelector | ((selector: UserModelSelector) => UserModelSelector)) { return this.__child(`user`, UserModelSelector, builder) }
  vault(builder?: string | VaultModelSelector | ((selector: VaultModelSelector) => VaultModelSelector)) { return this.__child(`vault`, VaultModelSelector, builder) }
}
export function selectFromLoginHistory() {
  return new LoginHistoryModelSelector()
}

export const loginHistoryModelPrimitives = selectFromLoginHistory().timestamp

/* This is a mk-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
import type { ObservableMap } from "mobx"
import { types, prop, tProp, Ref, Model, modelAction, objectMap, detach, model, findParent, customRef, ExtendedModel, AbstractModelClass } from "mobx-keystone"
import { MKGQLStore, createMKGQLStore, QueryOptions } from "mk-gql"
import { MergeHelper } from './mergeHelper';

import { AlertModel, alertModelPrimitives, AlertModelSelector  } from "./AlertModel"
import { AlertCountModel, alertCountModelPrimitives, AlertCountModelSelector  } from "./AlertCountModel"
import { AlertCountsModel, alertCountsModelPrimitives, AlertCountsModelSelector  } from "./AlertCountsModel"
import { CamerasPresentModel, camerasPresentModelPrimitives, CamerasPresentModelSelector  } from "./CamerasPresentModel"
import { DeviceModel, deviceModelPrimitives, DeviceModelSelector  } from "./DeviceModel"
import { DeviceHistoryModel, deviceHistoryModelPrimitives, DeviceHistoryModelSelector  } from "./DeviceHistoryModel"
import { EventModel, eventModelPrimitives, EventModelSelector  } from "./EventModel"
import { LoginHistoryModel, loginHistoryModelPrimitives, LoginHistoryModelSelector  } from "./LoginHistoryModel"
import { SiteModel, siteModelPrimitives, SiteModelSelector  } from "./SiteModel"
import { StatsModel, statsModelPrimitives, StatsModelSelector  } from "./StatsModel"
import { UserModel, userModelPrimitives, UserModelSelector  } from "./UserModel"
import { VaultModel, vaultModelPrimitives, VaultModelSelector  } from "./VaultModel"
import { VaultEventModel, vaultEventModelPrimitives, VaultEventModelSelector  } from "./VaultEventModel"


import type { AlertCriticality } from "./AlertCriticalityEnum"
import type { NotificationLevels } from "./NotificationLevelsEnum"
import type { RoleLevels } from "./RoleLevelsEnum"

export type DeviceInput = {
  deviceType?: string
  firmwareVersion?: string
  id?: number
  manufacturer?: string
  serialNumber?: string
  site?: number
  telitThingId?: string
}
export type SiteInput = {
  fullAddress?: string
  id?: number
  latitude?: number
  longitude?: number
  name?: string
  siteManager?: string
}
export type SortInput = {
  field: string
  sort?: string
}
export type UserInput = {
  dashboardNotifications?: NotificationLevels
  email?: string
  emailNotifications?: NotificationLevels
  firstName?: string
  id?: string
  lastModified?: any
  lastName?: string
  middleName?: string
  notifications?: boolean
  password?: string
  phone?: string
  role?: RoleLevels
  secondaryEmail?: string
  secondaryPhone?: string
  sites?: number[]
  textNotifications?: NotificationLevels
  twofaEnabled?: boolean
  username?: string
  vaultPin?: string
}
export type VaultInput = {
  firmwareVersion?: string
  id?: number
  serialNumber?: string
  site?: number
  telitThingId?: string
  vaultType?: string
}
/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */


type Refs = {
  alerts: ObservableMap<string, AlertModel>,
  camerasPresents: ObservableMap<string, CamerasPresentModel>,
  devices: ObservableMap<string, DeviceModel>,
  deviceHistories: ObservableMap<string, DeviceHistoryModel>,
  events: ObservableMap<string, EventModel>,
  loginHistories: ObservableMap<string, LoginHistoryModel>,
  sites: ObservableMap<string, SiteModel>,
  stats: ObservableMap<string, StatsModel>,
  users: ObservableMap<string, UserModel>,
  vaults: ObservableMap<string, VaultModel>,
  vaultEvents: ObservableMap<string, VaultEventModel>
}


/**
* Enums for the names of base graphql actions
*/
export enum RootStoreBaseQueries {
queryAlertCount="queryAlertCount",
queryAlerts="queryAlerts",
queryCheckUserExists="queryCheckUserExists",
queryDevices="queryDevices",
queryEvents="queryEvents",
querySites="querySites",
queryStats="queryStats",
queryTotalEvents="queryTotalEvents",
queryTotalVaultEvents="queryTotalVaultEvents",
queryTruckEvents="queryTruckEvents",
queryUsers="queryUsers",
queryVaultEvents="queryVaultEvents",
queryVaults="queryVaults"
}
export enum RootStoreBaseMutations {
mutateCreateDevice="mutateCreateDevice",
mutateCreateSite="mutateCreateSite",
mutateCreateUser="mutateCreateUser",
mutateCreateVault="mutateCreateVault",
mutateDeleteDevice="mutateDeleteDevice",
mutateDeleteUser="mutateDeleteUser",
mutateDeleteVault="mutateDeleteVault",
mutateEditDevices="mutateEditDevices",
mutateEditSites="mutateEditSites",
mutateEditUser="mutateEditUser",
mutateEditVaults="mutateEditVaults",
mutateSetAssignedTo="mutateSetAssignedTo",
mutateSetHandledBy="mutateSetHandledBy",
mutateSignOut="mutateSignOut",
mutateToggleMFA="mutateToggleMFA",
mutateVerifyTOTPCode="mutateVerifyTOTPCode"
}

/**
* Store, managing, among others, all the objects received through graphQL
*/
export class RootStoreBase extends ExtendedModel(createMKGQLStore<AbstractModelClass<MKGQLStore>>([['Alert', () => AlertModel], ['AlertCount', () => AlertCountModel], ['AlertCounts', () => AlertCountsModel], ['CamerasPresent', () => CamerasPresentModel], ['Device', () => DeviceModel], ['DeviceHistory', () => DeviceHistoryModel], ['Event', () => EventModel], ['LoginHistory', () => LoginHistoryModel], ['Site', () => SiteModel], ['Stats', () => StatsModel], ['User', () => UserModel], ['Vault', () => VaultModel], ['VaultEvent', () => VaultEventModel]], ['Alert', 'CamerasPresent', 'Device', 'DeviceHistory', 'Event', 'LoginHistory', 'Site', 'Stats', 'User', 'Vault', 'VaultEvent'] , "js"),{
    alerts: prop(() => objectMap<AlertModel>()),
    camerasPresents: prop(() => objectMap<CamerasPresentModel>()),
    devices: prop(() => objectMap<DeviceModel>()),
    deviceHistories: prop(() => objectMap<DeviceHistoryModel>()),
    events: prop(() => objectMap<EventModel>()),
    loginHistories: prop(() => objectMap<LoginHistoryModel>()),
    sites: prop(() => objectMap<SiteModel>()),
    stats: prop(() => objectMap<StatsModel>()),
    users: prop(() => objectMap<UserModel>()),
    vaults: prop(() => objectMap<VaultModel>()),
    vaultEvents: prop(() => objectMap<VaultEventModel>()), 
    mergeHelper: prop<MergeHelper>(() => new MergeHelper({}))
  }) {
  
    @modelAction queryAlertCount(variables?: {  }, resultSelector: string | ((qb: typeof AlertCountsModelSelector) => typeof AlertCountsModelSelector) = alertCountsModelPrimitives.toString() , options: QueryOptions = {}, clean?: boolean) {
      return this.query<{ alertCount: AlertCountsModel}>(`query alertCount { alertCount {
        ${typeof resultSelector === "function" ? resultSelector(AlertCountsModelSelector).toString() : resultSelector}
      } }`, variables, options, !!clean)
    }
    @modelAction queryAlerts(variables?: { alertCriticality?: AlertCriticality, offset?: number, startTime?: any }, resultSelector: string | ((qb: typeof AlertModelSelector) => typeof AlertModelSelector) = alertModelPrimitives.toString() , options: QueryOptions = {}, clean?: boolean) {
      return this.query<{ alerts: AlertModel[]}>(`query alerts($alertCriticality: AlertCriticality, $offset: Float, $startTime: DateTimeISO) { alerts(alertCriticality: $alertCriticality, offset: $offset, startTime: $startTime) {
        ${typeof resultSelector === "function" ? resultSelector(AlertModelSelector).toString() : resultSelector}
      } }`, variables, options, !!clean)
    }
    @modelAction queryCheckUserExists(variables: { username: string }, _?: any , options: QueryOptions = {}, clean?: boolean) {
      return this.query<{ checkUserExists: boolean }>(`query checkUserExists($username: String!) { checkUserExists(username: $username)  }`, variables, options, !!clean)
    }
    @modelAction queryDevices(variables?: {  }, resultSelector: string | ((qb: typeof DeviceModelSelector) => typeof DeviceModelSelector) = deviceModelPrimitives.toString() , options: QueryOptions = {}, clean?: boolean) {
      return this.query<{ devices: DeviceModel[]}>(`query devices { devices {
        ${typeof resultSelector === "function" ? resultSelector(DeviceModelSelector).toString() : resultSelector}
      } }`, variables, options, !!clean)
    }
    @modelAction queryEvents(variables?: { deviceId?: string | number, page?: number, rowsPerPage?: number, sort?: SortInput[] }, resultSelector: string | ((qb: typeof EventModelSelector) => typeof EventModelSelector) = eventModelPrimitives.toString() , options: QueryOptions = {}, clean?: boolean) {
      return this.query<{ events: EventModel[]}>(`query events($deviceId: ID, $page: Float, $rowsPerPage: Float, $sort: [SortInput!]) { events(deviceId: $deviceId, page: $page, rowsPerPage: $rowsPerPage, sort: $sort) {
        ${typeof resultSelector === "function" ? resultSelector(EventModelSelector).toString() : resultSelector}
      } }`, variables, options, !!clean)
    }
    @modelAction querySites(variables?: {  }, resultSelector: string | ((qb: typeof SiteModelSelector) => typeof SiteModelSelector) = siteModelPrimitives.toString() , options: QueryOptions = {}, clean?: boolean) {
      return this.query<{ sites: SiteModel[]}>(`query sites { sites {
        ${typeof resultSelector === "function" ? resultSelector(SiteModelSelector).toString() : resultSelector}
      } }`, variables, options, !!clean)
    }
    @modelAction queryStats(variables: { id: number }, resultSelector: string | ((qb: typeof StatsModelSelector) => typeof StatsModelSelector) = statsModelPrimitives.toString() , options: QueryOptions = {}, clean?: boolean) {
      return this.query<{ stats: StatsModel[]}>(`query stats($id: Float!) { stats(id: $id) {
        ${typeof resultSelector === "function" ? resultSelector(StatsModelSelector).toString() : resultSelector}
      } }`, variables, options, !!clean)
    }
    @modelAction queryTotalEvents(variables?: { deviceId?: string }, _?: any , options: QueryOptions = {}, clean?: boolean) {
      return this.query<{ totalEvents: number }>(`query totalEvents($deviceId: String) { totalEvents(deviceId: $deviceId)  }`, variables, options, !!clean)
    }
    @modelAction queryTotalVaultEvents(variables?: {  }, _?: any , options: QueryOptions = {}, clean?: boolean) {
      return this.query<{ totalVaultEvents: number }>(`query totalVaultEvents { totalVaultEvents  }`, variables, options, !!clean)
    }
    @modelAction queryTruckEvents(variables: { interval: number }, resultSelector: string | ((qb: typeof EventModelSelector) => typeof EventModelSelector) = eventModelPrimitives.toString() , options: QueryOptions = {}, clean?: boolean) {
      return this.query<{ truckEvents: EventModel[]}>(`query truckEvents($interval: Float!) { truckEvents(interval: $interval) {
        ${typeof resultSelector === "function" ? resultSelector(EventModelSelector).toString() : resultSelector}
      } }`, variables, options, !!clean)
    }
    @modelAction queryUsers(variables?: {  }, resultSelector: string | ((qb: typeof UserModelSelector) => typeof UserModelSelector) = userModelPrimitives.toString() , options: QueryOptions = {}, clean?: boolean) {
      return this.query<{ users: UserModel[]}>(`query users { users {
        ${typeof resultSelector === "function" ? resultSelector(UserModelSelector).toString() : resultSelector}
      } }`, variables, options, !!clean)
    }
    @modelAction queryVaultEvents(variables?: { page?: number, rowsPerPage?: number }, resultSelector: string | ((qb: typeof VaultEventModelSelector) => typeof VaultEventModelSelector) = vaultEventModelPrimitives.toString() , options: QueryOptions = {}, clean?: boolean) {
      return this.query<{ vaultEvents: VaultEventModel[]}>(`query vaultEvents($page: Float, $rowsPerPage: Float) { vaultEvents(page: $page, rowsPerPage: $rowsPerPage) {
        ${typeof resultSelector === "function" ? resultSelector(VaultEventModelSelector).toString() : resultSelector}
      } }`, variables, options, !!clean)
    }
    @modelAction queryVaults(variables?: {  }, resultSelector: string | ((qb: typeof VaultModelSelector) => typeof VaultModelSelector) = vaultModelPrimitives.toString() , options: QueryOptions = {}, clean?: boolean) {
      return this.query<{ vaults: VaultModel[]}>(`query vaults { vaults {
        ${typeof resultSelector === "function" ? resultSelector(VaultModelSelector).toString() : resultSelector}
      } }`, variables, options, !!clean)
    }
    @modelAction mutateCreateDevice(variables: { device: DeviceInput }, resultSelector: string | ((qb: typeof DeviceModelSelector) => typeof DeviceModelSelector) = deviceModelPrimitives.toString() , optimisticUpdate?: () => void) {
      return this.mutate<{ createDevice: DeviceModel}>(`mutation createDevice($device: DeviceInput!) { createDevice(device: $device) {
        ${typeof resultSelector === "function" ? resultSelector(DeviceModelSelector).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    }
    @modelAction mutateCreateSite(variables: { site: SiteInput }, resultSelector: string | ((qb: typeof SiteModelSelector) => typeof SiteModelSelector) = siteModelPrimitives.toString() , optimisticUpdate?: () => void) {
      return this.mutate<{ createSite: SiteModel}>(`mutation createSite($site: SiteInput!) { createSite(site: $site) {
        ${typeof resultSelector === "function" ? resultSelector(SiteModelSelector).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    }
    @modelAction mutateCreateUser(variables: { user: UserInput }, resultSelector: string | ((qb: typeof UserModelSelector) => typeof UserModelSelector) = userModelPrimitives.toString() , optimisticUpdate?: () => void) {
      return this.mutate<{ createUser: UserModel}>(`mutation createUser($user: UserInput!) { createUser(user: $user) {
        ${typeof resultSelector === "function" ? resultSelector(UserModelSelector).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    }
    @modelAction mutateCreateVault(variables: { vault: VaultInput }, resultSelector: string | ((qb: typeof VaultModelSelector) => typeof VaultModelSelector) = vaultModelPrimitives.toString() , optimisticUpdate?: () => void) {
      return this.mutate<{ createVault: VaultModel}>(`mutation createVault($vault: VaultInput!) { createVault(vault: $vault) {
        ${typeof resultSelector === "function" ? resultSelector(VaultModelSelector).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    }
    @modelAction mutateDeleteDevice(variables: { device: DeviceInput }, _?: any , optimisticUpdate?: () => void) {
      return this.mutate<{ deleteDevice: boolean }>(`mutation deleteDevice($device: DeviceInput!) { deleteDevice(device: $device)  }`, variables, optimisticUpdate)
    }
    @modelAction mutateDeleteUser(variables: { username: string }, _?: any , optimisticUpdate?: () => void) {
      return this.mutate<{ deleteUser: boolean }>(`mutation deleteUser($username: String!) { deleteUser(username: $username)  }`, variables, optimisticUpdate)
    }
    @modelAction mutateDeleteVault(variables: { vault: VaultInput }, _?: any , optimisticUpdate?: () => void) {
      return this.mutate<{ deleteVault: boolean }>(`mutation deleteVault($vault: VaultInput!) { deleteVault(vault: $vault)  }`, variables, optimisticUpdate)
    }
    @modelAction mutateEditDevices(variables: { devices: DeviceInput[] }, resultSelector: string | ((qb: typeof DeviceModelSelector) => typeof DeviceModelSelector) = deviceModelPrimitives.toString() , optimisticUpdate?: () => void) {
      return this.mutate<{ editDevices: DeviceModel[]}>(`mutation editDevices($devices: [DeviceInput!]!) { editDevices(devices: $devices) {
        ${typeof resultSelector === "function" ? resultSelector(DeviceModelSelector).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    }
    @modelAction mutateEditSites(variables: { sites: SiteInput[] }, resultSelector: string | ((qb: typeof SiteModelSelector) => typeof SiteModelSelector) = siteModelPrimitives.toString() , optimisticUpdate?: () => void) {
      return this.mutate<{ editSites: SiteModel[]}>(`mutation editSites($sites: [SiteInput!]!) { editSites(sites: $sites) {
        ${typeof resultSelector === "function" ? resultSelector(SiteModelSelector).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    }
    @modelAction mutateEditUser(variables: { user: UserInput }, resultSelector: string | ((qb: typeof UserModelSelector) => typeof UserModelSelector) = userModelPrimitives.toString() , optimisticUpdate?: () => void) {
      return this.mutate<{ editUser: UserModel}>(`mutation editUser($user: UserInput!) { editUser(user: $user) {
        ${typeof resultSelector === "function" ? resultSelector(UserModelSelector).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    }
    @modelAction mutateEditVaults(variables: { vaults: VaultInput[] }, resultSelector: string | ((qb: typeof VaultModelSelector) => typeof VaultModelSelector) = vaultModelPrimitives.toString() , optimisticUpdate?: () => void) {
      return this.mutate<{ editVaults: VaultModel[]}>(`mutation editVaults($vaults: [VaultInput!]!) { editVaults(vaults: $vaults) {
        ${typeof resultSelector === "function" ? resultSelector(VaultModelSelector).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    }
    @modelAction mutateSetAssignedTo(variables: { alertId: number, userId: string }, resultSelector: string | ((qb: typeof AlertModelSelector) => typeof AlertModelSelector) = alertModelPrimitives.toString() , optimisticUpdate?: () => void) {
      return this.mutate<{ setAssignedTo: AlertModel}>(`mutation setAssignedTo($alertId: Float!, $userId: String!) { setAssignedTo(alertId: $alertId, userId: $userId) {
        ${typeof resultSelector === "function" ? resultSelector(AlertModelSelector).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    }
    @modelAction mutateSetHandledBy(variables: { alertId: number, userId: string }, resultSelector: string | ((qb: typeof AlertModelSelector) => typeof AlertModelSelector) = alertModelPrimitives.toString() , optimisticUpdate?: () => void) {
      return this.mutate<{ setHandledBy: AlertModel}>(`mutation setHandledBy($alertId: Float!, $userId: String!) { setHandledBy(alertId: $alertId, userId: $userId) {
        ${typeof resultSelector === "function" ? resultSelector(AlertModelSelector).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    }
    @modelAction mutateSignOut(variables?: {  }, _?: any , optimisticUpdate?: () => void) {
      return this.mutate<{ signOut: string }>(`mutation signOut { signOut  }`, variables, optimisticUpdate)
    }
    @modelAction mutateToggleMFA(variables: { enabled: boolean }, _?: any , optimisticUpdate?: () => void) {
      return this.mutate<{ toggleMFA: string }>(`mutation toggleMFA($enabled: Boolean!) { toggleMFA(enabled: $enabled)  }`, variables, optimisticUpdate)
    }
    @modelAction mutateVerifyTOTPCode(variables: { code: string, deviceName?: string }, _?: any , optimisticUpdate?: () => void) {
      return this.mutate<{ verifyTOTPCode: string }>(`mutation verifyTOTPCode($code: String!, $deviceName: String) { verifyTOTPCode(code: $code, deviceName: $deviceName)  }`, variables, optimisticUpdate)
    }
  }
  function resolve(path, obj={}, separator='.') {
    const properties = Array.isArray(path) ? path : path.split(separator)
    return properties.reduce((prev, curr) => prev && prev[curr], obj)
}

export const appRef = <T extends object>(storeInstance, modelTypeId, path) =>
  customRef<T>(`RootStore/${modelTypeId}`, {
    resolve: (ref: Ref<any>) =>
      resolve(path, findParent<typeof storeInstance>(ref, (n) => n instanceof storeInstance))?.get(ref?.id),
    onResolvedValueChange(ref, newItem, oldItem) {
      if (oldItem && !newItem) detach(ref);
    },
  });

export const alertsRef = appRef<AlertModel>(RootStoreBase, "Alert", 'alerts')
    
export const camerasPresentsRef = appRef<CamerasPresentModel>(RootStoreBase, "CamerasPresent", 'camerasPresents')
    
export const devicesRef = appRef<DeviceModel>(RootStoreBase, "Device", 'devices')
    
export const deviceHistoriesRef = appRef<DeviceHistoryModel>(RootStoreBase, "DeviceHistory", 'deviceHistories')
    
export const eventsRef = appRef<EventModel>(RootStoreBase, "Event", 'events')
    
export const loginHistoriesRef = appRef<LoginHistoryModel>(RootStoreBase, "LoginHistory", 'loginHistories')
    
export const sitesRef = appRef<SiteModel>(RootStoreBase, "Site", 'sites')
    
export const statsRef = appRef<StatsModel>(RootStoreBase, "Stats", 'stats')
    
export const usersRef = appRef<UserModel>(RootStoreBase, "User", 'users')
    
export const vaultsRef = appRef<VaultModel>(RootStoreBase, "Vault", 'vaults')
    
export const vaultEventsRef = appRef<VaultEventModel>(RootStoreBase, "VaultEvent", 'vaultEvents')
    
  export const rootRefs = {
  alerts: alertsRef,
  camerasPresents: camerasPresentsRef,
  devices: devicesRef,
  deviceHistories: deviceHistoriesRef,
  events: eventsRef,
  loginHistories: loginHistoriesRef,
  sites: sitesRef,
  stats: statsRef,
  users: usersRef,
  vaults: vaultsRef,
  vaultEvents: vaultEventsRef
}

import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useQuery } from 'react-query';
import { Router } from './Router';
import { ErrorBoundary } from './components/ErrorBoundary';
import { Loading } from './components/Loader';
import { useAppStore } from './store';
import { Modal } from './views/modal/RootModal';
import { DashboardNav } from './views/nav';

import './styles.css';
import { NotificationsHandler } from './views/notifications/NotificationsHandler';

export const App = observer(() => {
  const store = useAppStore();
  const { failureCount } = useQuery('check-login', async () => await store.auth.checkLogin(), {
    retry: 5,
    retryDelay: 2500,
  });
  return (
    <NotificationsHandler>
      {!store.auth.username ? (
        <Loading>
          <Box textAlign="center">
            <Typography variant="h6">Checking Login Status</Typography>
            {failureCount > 1 ? (
              <Typography>(Attempt {failureCount - 1}) Unable to communicate with server, retrying...</Typography>
            ) : null}
          </Box>
        </Loading>
      ) : (
        <div className="dashboard-app">
          <Modal />
          <DashboardNav />
          <main>
            <div className="dashboard-body">
              <ErrorBoundary>
                <Router />
              </ErrorBoundary>
            </div>
          </main>
        </div>
      )}
    </NotificationsHandler>
  );
});

import { ExtendedModel, model } from 'mobx-keystone';
import { EventModelBase } from './EventModel.base';

/* A graphql query fragment builders for EventModel */
export { selectFromEvent, eventModelPrimitives, EventModelSelector } from './EventModel.base';

/**
 * EventModel
 */
@model('Event')
export class EventModel extends ExtendedModel(EventModelBase, {}) {}

import 'reflect-metadata';
import { ThemeProvider } from '@material-ui/core';
import { configure } from 'mobx';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { TranslationProvider } from '@ir/client/translations/Provider';
import { darkTheme } from '@ir/client/types/theme';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import glbl from 'jss-plugin-global';
import { App } from './App';
import { AppStoreProvider, appStore } from './store';

configure({ enforceActions: 'never' });

const jss = create({
  // @ts-ignore
  plugins: [...jssPreset().plugins, glbl()],
});

render(
  <StrictMode>
    <TranslationProvider>
      {/*// @ts-ignore */}
      <StylesProvider jss={jss}>
        <ThemeProvider theme={darkTheme}>
          <AppStoreProvider store={appStore}>
            <QueryClientProvider client={appStore.queryClient}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </QueryClientProvider>
          </AppStoreProvider>
        </ThemeProvider>
      </StylesProvider>
    </TranslationProvider>
  </StrictMode>,
  document.getElementById('app')!
);

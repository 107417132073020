import type { Theme } from '@material-ui/core';
import type { Map as LeafletMap, LeafletMouseEvent, MarkerCluster } from 'leaflet';
import { makeAutoObservable, observable } from 'mobx';
import { computedFn } from 'mobx-utils';
import type { ReactNode } from 'react';

type Filters = { [K in keyof Theme['status'] as Theme['status'][K]['name']]: boolean };

export interface MenuPanel {
  name: string;
  button: ReactNode;
  element: ReactNode;
  mapElement?: any;
}

export class MapState {
  position?: any = null;
  detailData: any = observable.object({});
  detailType?: any | string | null = null;
  detailVisible: boolean = false;
  map: LeafletMap | undefined = undefined;

  menuPanels: { selected: string | undefined; panels: MenuPanel[] } = {
    selected: undefined,
    panels: [],
  };

  filters = {
    Docked: true,
    'In Use': true,
    Unknown: true,
    Critical: true,
  } satisfies Filters;

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedMenuPanel(panelName: string) {
    this.menuPanels.selected = panelName;
  }

  isSelectedMenuPanel = computedFn((panelName: string) => this.menuPanels.selected === panelName);

  onMapEvent = (props: LeafletMouseEvent) => {
    if (props?.type === 'clusterclick' || props?.type === 'click') {
      const isDevice =
        props?.target?.options?.clusterType === 'latest-device' ||
        props?.sourceTarget?.options?.clusterType === 'latest-device';

      const marker = props?.layer ? (props.layer as MarkerCluster) : undefined;
      let markers = [];
      if (isDevice) {
        if (marker && props?.type === 'clusterclick') {
          markers = marker?.getAllChildMarkers()?.map((m) => {
            // @ts-ignore
            return m?.options?.id;
          });
        } else {
          markers = [props?.sourceTarget?.options?.id];
        }
        markers = markers.filter((m) => !!m);
        if (!this.detailVisible) {
          this.openDetail('tabular', { category: 'device', markers });
        } else {
          this.updateDetailType('tabular', { category: 'device', markers });
        }
      }
      // if (props?.type === 'click') {
      //   if (props?.latlng) this.map?.fitBounds(props.latlng?.toBounds(2000), { maxZoom: 18, padding: [20, 20] });
      // } else if (props?.type === 'clusterclick') {
      //   if (marker?.getBounds()?.isValid()) this.map.fitBounds(marker?.getBounds(), { maxZoom: 18, padding: [20, 20] });
      // }
      if (typeof marker?.spiderfy === 'function') marker?.spiderfy?.();
    }
  };

  closeDetail() {
    this.detailData = {};
    this.detailType = null;
    this.detailVisible = false;
  }

  openDetail(type, data) {
    this.detailData = data;
    this.detailType = type;
    this.detailVisible = true;
  }

  updateDetailType(type, data) {
    this.detailData = data;
    this.detailType = type;
  }

  toggleFilter(filter) {
    this.filters[filter] = !this.filters[filter];
  }

  get filterCount() {
    const count = { active: 0, inactive: 0 };
    for (const filter in this.filters) {
      if (this.filters[filter]) count.active++;
      else count.inactive++;
    }
    return { ...count, total: count.active + count.inactive };
  }
}

/* This is a mk-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

import { types, prop, tProp, Model, Ref, idProp } from "mobx-keystone"
import { QueryBuilder } from "mk-gql"
import type { UserModel } from "./UserModel"


import { UserModelSelector, userModelPrimitives } from "./UserModel"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  siteManager: UserModel;
}

/**
 * SiteBase
 * auto generated base class for the model SiteModel.
 */
export class SiteModelBase extends Model({
    __typename: tProp("Site"),
        fullAddress:prop<string | null>().withSetter(),
    id:prop<number>().withSetter(),
    lastModified:prop<any | null>().withSetter(),
    latitude:prop<number | null>().withSetter(),
    longitude:prop<number | null>().withSetter(),
    name:prop<string | null>().withSetter(),
    siteManager:prop<Ref<UserModel> | null>().withSetter(),
  }) {
    getRefId() {
      return String(this.id)
    }
  }

export class SiteModelSelector extends QueryBuilder {
  get fullAddress() { return this.__attr(`fullAddress`) }
  get id() { return this.__attr(`id`) }
  get lastModified() { return this.__attr(`lastModified`) }
  get latitude() { return this.__attr(`latitude`) }
  get longitude() { return this.__attr(`longitude`) }
  get name() { return this.__attr(`name`) }
  siteManager(builder?: string | UserModelSelector | ((selector: UserModelSelector) => UserModelSelector)) { return this.__child(`siteManager`, UserModelSelector, builder) }
}
export function selectFromSite() {
  return new SiteModelSelector()
}

export const siteModelPrimitives = selectFromSite().fullAddress.lastModified.latitude.longitude.name

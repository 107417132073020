/* This is a mk-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

import { types, prop, tProp, Model, Ref, idProp } from "mobx-keystone"
import { QueryBuilder } from "mk-gql"
import type { AlertCriticality } from "./AlertCriticalityEnum"
import type { DeviceModel } from "./DeviceModel"
import type { UserModel } from "./UserModel"
import type { VaultModel } from "./VaultModel"


import { DeviceModelSelector, deviceModelPrimitives } from "./DeviceModel"
import { UserModelSelector, userModelPrimitives } from "./UserModel"
import { VaultModelSelector, vaultModelPrimitives } from "./VaultModel"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  assignedTo: UserModel;
  device: DeviceModel;
  handledBy: UserModel;
  vault: VaultModel;
}

/**
 * AlertBase
 * auto generated base class for the model AlertModel.
 */
export class AlertModelBase extends Model({
    __typename: tProp("Alert"),
        assignedTo:prop<Ref<UserModel> | null>().withSetter(),
    criticality:prop<AlertCriticality | null>().withSetter(),
    device:prop<Ref<DeviceModel> | null>().withSetter(),
    handledBy:prop<Ref<UserModel> | null>().withSetter(),
    handledTimestamp:prop<any | null>().withSetter(),
    id:prop<number>().withSetter(),
    isHandled:prop<boolean | null>().withSetter(),
    timestamp:prop<any | null>().withSetter(),
    type:prop<string | null>().withSetter(),
    vault:prop<Ref<VaultModel> | null>().withSetter(),
  }) {
    getRefId() {
      return String(this.id)
    }
  }

export class AlertModelSelector extends QueryBuilder {
  get criticality() { return this.__attr(`criticality`) }
  get handledTimestamp() { return this.__attr(`handledTimestamp`) }
  get id() { return this.__attr(`id`) }
  get isHandled() { return this.__attr(`isHandled`) }
  get timestamp() { return this.__attr(`timestamp`) }
  get type() { return this.__attr(`type`) }
  assignedTo(builder?: string | UserModelSelector | ((selector: UserModelSelector) => UserModelSelector)) { return this.__child(`assignedTo`, UserModelSelector, builder) }
  device(builder?: string | DeviceModelSelector | ((selector: DeviceModelSelector) => DeviceModelSelector)) { return this.__child(`device`, DeviceModelSelector, builder) }
  handledBy(builder?: string | UserModelSelector | ((selector: UserModelSelector) => UserModelSelector)) { return this.__child(`handledBy`, UserModelSelector, builder) }
  vault(builder?: string | VaultModelSelector | ((selector: VaultModelSelector) => VaultModelSelector)) { return this.__child(`vault`, VaultModelSelector, builder) }
}
export function selectFromAlert() {
  return new AlertModelSelector()
}

export const alertModelPrimitives = selectFromAlert().criticality.handledTimestamp.isHandled.timestamp.type
